// MAINS VAR
$browser-context: 16px;

// PATRHS
$parent-path: '../../front-end/';
$parent-path-front: '../';

$image-path: $parent-path + 'img/';
$image-path-front: $parent-path-front + 'img/';

$font-path: $parent-path + 'fonts/';
$font-path-front: $parent-path-front + 'font/';

// COLORS
$white: #fff;
$black: #000;
$grey: #ededed;
$lass-dark-grey: darken($grey, 5);

$dark-grey: darken($grey, 20);
$u-light-grey: lighten($dark-grey, 20);
$light-grey: lighten($dark-grey, 10);
$ultra-dark-grey: darken($grey, 80);
$mid-dark-grey: darken($grey,55);




$brown: #71411b;
$darkmagenta: #8b008b;
$darkviolet: #9400d3;
$red: rgb(252, 48, 48);
$green: rgb(35, 205, 0);
$orange: #f27822;
$darken-orange: darken($orange, 10);
$grey-border: #a8afb4;


$main-color: $darkmagenta;
$second-color: $darkviolet;



// SIZES
$max-width: 1188px;
/*  header  */

$gap : 2%;
$col : 4;

$fs-big-bloc: 40px;
$font-size-button-bloc: 18px;

/* News */
$gap-news: 2;
$pinned-news-count: 2;
$news-count: 4;

// FONTS
$font-asap: 'Asap', sans-serif;
$font-oswald: 'Oswald', sans-serif;
$font-raleway: 'Raleway', sans-serif;
$font-verdana: Verdana, Geneva, sans-serif;
$normal-font: null;
$big-font: null;

$breakpoints: (
  'small':  ( min-width:  767px ),
  'medium': ( min-width:  992px ),
  '1042': ( min-width:  1042px ),
  'large':  ( min-width: 1200px ),
  'm1042':  ( max-width: 1042px ),
  'msmall':  ( max-width: 767px ),
  'mmedium': ( max-width:  992px )
) !default;
