.wide {
  @extend %wide-style;
  & strong {
    color:$second-color;
  }
}

.wideB {
  @extend %wide-style;
  border:none;
  padding:0px;
}

.wide span, .wideB span {
@extend %span-style;
}

#btn-big {

  background-color:#e9e7e8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding:30px;
  clear:both;
  margin-bottom:30px;
  width:100%;
  box-sizing: border-box;
  margin-top: 1em;

  @include bp('1042') {
      width:652px;
      box-sizing: inherit;
        margin-top: 0;
  }

  a {

   height:54px;
   display:block;
   line-height:54px;
   float:left;
   text-decoration:none;
   text-align:center;
   background:$second-color;
   color:$white;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   border-radius: 5px;
   font-size:24px;
   width: 100%;


   &:first-child {
     margin-bottom: .5em
   }

   @include bp('1042') {
      width:311px;
      margin-bottom: 0!important;
   }



   &:hover {
     background:$main-color;
     color:$white;
   }

   &:first-child {
     margin-right:30px;
   }
 }
}

.sep {
  background:url($image-path-front + 'pix.jpg') left center repeat-x;
  height:115px;
  width:100%;
  position:relative;
  margin-bottom:2.5em;
  clear:both;

  @include bp('1042') {
    margin-bottom:20px;
  }

  img {
    position:absolute;
    left:50%;
    top:0;
    margin-left:-57px;
  }
}

.pourcent {
  width:320px;
  margin: 0 auto;
  margin-bottom:20px;
  height:90px;

  p {
    font-size:18px;
    color:#000;
    line-height:5px;
    float:left;
    font-family:'league_gothicregular' , arial, sans-serif;
    margin-top:25px;
    margin-left:20px;

    span {
      font-size:40px;
      line-height:40px;
    }
  }

  img {
    float:left;
  }
}

.table {
  display: table;
  width: 100%;
  border-collapse:collapse;
  font-family:Verdana, Geneva, sans-serif;
  font-size: 10px;
  line-height: 14px;
  letter-spacing:1px;
  color: $black;
  text-align:center;
  margin-bottom:40px;

  @include bp('m1042') {
    display: block;
  }

  th {
    padding-top:20px;
    border-bottom:none;
  }

  .tr{
    display: table-row;
    vertical-align:top;
    border-top: 1px dashed #000;

    @include bp('m1042') {
      display: inline;
      width: 100%;
      border: none;
    }

    &:first-child{
      border:none;
    }
  }

  .td{
    display:table-cell;
    vertical-align: top;
    padding:3em 1.5em;
    border-left: 1px dashed #000;
    width: (100% / 3);

    &.noshow{
      @include bp('m1042') {
        display: none;
      }
    }

    @include bp('m1042') {
      display: inline-block;
      width: 47.5%;
      box-sizing: border-box;
      border: none;
    }

    @include bp('msmall') {
      display: block;
      width: 100%;
    }

    &:first-child{
      border-left:none;
    }

    strong {
      font-size:50px;
      color:$second-color;
      font-family:'league_gothicregular' , arial, sans-serif;

      span {
        font-size:12px;
        padding-top:5px;
        font-family:Verdana, Geneva, sans-serif;
      }
    }
  }
}
