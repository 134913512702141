

		/*.mini a, .miniR a {
			color:#71411b;
			text-decoration:none;
		}

		.mini a:hover, .miniR a:hover {
			text-decoration:underline;
		}*/


		.mini {
			width:326px;
			float:left;
			padding-right:30px;
			margin-bottom:20px;
			text-align:center;
			font-size:12px;
			line-height:24px;
			margin-bottom: 4em;

			@include bp('1042') {
				border-right:1px dashed #000;
				margin-bottom: 0;
			}
		}

		.miniR {
			width:326px;
			float:right;
			margin-bottom: 4em;
			text-align:center;
			font-size:12px;
			line-height:24px;

			@include bp('1042') {
				margin-bottom: 0;
			}
		}

		.mini, .miniR {

			@include bp('m1042') {
				width: 100%;
			}

			span {
				@extend %span-style;

				&.quote {
					font-size: 22px;
					margin-top: 5px;
					display: block;
				}
			}

		}


.bbr {
	@include bp('m1042') {
		border: 1px dashed $black;
		border-radius: 5px;
		margin-right: 0;
		padding:3em 2em;
		box-sizing: border-box;
		float: none;
	}

	a {
		@include bp('m1042') {
			margin-bottom: 0;
		}
	}

	&:nth-last-child(2) {
		@include bp('m1042') {
			margin-bottom: 0;
		}
	}
}
