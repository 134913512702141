#footer {
  background:url($image-path-front + 'sep_footer.jpg') top left no-repeat $main-color;
  margin:0 auto;
  padding:2em 0;

  @include bp('1042') {
    padding:30px 0 20px 0;
    width:902px;
  }

  img {
    width:85px;
    margin:0 auto;
    display: block;
  }

  p {
    color:$white;
    line-height:12px;
    text-align:center;
    font-size:9px;
    padding-top:20px;



    a {
      color:$white;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;

      &:hover {
        text-decoration:none;
        background:$second-color;
        padding:10px;
      }
    }
  }
}
