@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function em($pixels, $context: $browser-context) {
  @return #{strip-unit($pixels)/strip-unit($context)}em;
}

@function ratio($base, $value, $percentage: true ) {
  $ratio : (strip-unit($value) * 100) / strip-unit($base);

  @if($percentage == true) {
    @return $ratio + %;
  } @else {
    @return $ratio
  }
}

@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

@function colunm($count, $gap, $unit: %, $size: 100) {
  @return ($size - ($gap * ($count - 1))) / $count + $unit;
}
