@font-face {
    font-family: 'league_gothicregular';
    src: url($font-path-front + 'leaguegothic-regular-webfont.eot');
    src: url($font-path-front + 'leaguegothic-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url($font-path-front + 'leaguegothic-regular-webfont.woff') format('woff'),
         url($font-path-front + 'leaguegothic-regular-webfont.ttf') format('truetype'),
         url($font-path-front + 'leaguegothic-regular-webfont.svg#league_gothicregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
