%pseudos {
  content: '';
  position: absolute;
}

%reset-button {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

%reset-ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

%generic-cont {
  background-color: $white;
  padding:2em;
  border: 1px solid $light-grey;
  border-radius: 5px;
}


%wide-style {

  clear:both;
  padding-top:20px;
  margin-bottom:20px;
  text-align:center;
  font-size:12px;
  line-height:24px;

  @include bp('1042') {
    border-top:1px dashed $black;
  }
}

%span-style {
  color:#000;
  font-family:'league_gothicregular' , arial, sans-serif;
  font-size:38px;
  text-transform:uppercase;
}
