#formulaire {
  height:346px;

  @include bp('mmedium') {
    height: auto;
  }

  .left, .right {
    @include bp('mmedium') {
      width: 100%;
      float: none;
      height: auto;
    }

  }

  div {
    @include bp('mmedium') {
      width: 100%;
      margin-right: 0;
    }
  }

  input, textarea {
    @include bp('mmedium') {
      width: 100%;
      margin-right: 0;
      box-sizing: border-box;
    }
  }
  textarea {
    @include bp('mmedium') {
      height: 6em;
    }
  }
}

#Message {
  height: auto;

  @include bp('1042') {
  height:245px;
}
}


.baseline {
  color:#CCC;
  font-size:10px;
  font-style:italic;
}



.google-maps {
       position: relative;
       padding-bottom: 75%; // This is the aspect ratio
       height: 0;
       overflow: hidden;
         margin-top:30px;

    iframe {
       position: absolute;
       top: 0;
       left: 0;
       width: 100% !important;
       height: 100% !important;
   }

 }
