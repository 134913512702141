#logoHeader {
  display: none;

  @include bp('1042') {
    display: block;
    width:100%;
    margin-top:120px;
    background:url($image-path-front + 'big_ribbon_back.png') left 14px no-repeat;

  }

}

#big_ribbon {
  height:92px;
  width:100%;
  background-color:$main-color;
  position:relative;
  left:0;
  top:0;
  z-index:50;
}

  #logo {
    position:absolute;
    left:344px;
    top:-88px;
  }

  .headerTitle {
    font-size:10px;
    color:#fff6d9;
    z-index:10;
  }

  .TittleContentLeft {
      img {
        z-index:20;
        position:absolute;
        left:46px;
        top:34px;
      }
      .headerTitle{
        position:absolute;
        left:54px;
        top:34px;
      }
    }

  .TittleContentRight {
    img {
      z-index:20;
      position:absolute;
      right:59px;
      top:34px;
    }
    .headerTitle{
      position:absolute;
      right:90px;
      top:34px;
    }
  }



  #subTitle {
    font-size:10px;
    color:$white;
    font-family:Verdana, Geneva, sans-serif;
    text-transform:uppercase;
    font-weight:bold;

     span {
        position:absolute;

      &:first-child {
        left:51px;
        top:60px;
      }

      &:last-child {
        right:42px;
        top:60px;
      }
    }
}

  #slideshow {
    z-index:40;
    margin-left:29px;
  }

  #menu {

    @include bp('1042') {
    background:url($image-path-front + 'small_ribbon_back.png') left top no-repeat;
    width:100%;
    margin-top:-14px;
    }

    ul {
        text-align: center;

        @include bp('1042') {
          text-align:left;
          padding-left:90px;
        }

      li {

        list-style-type:none;

        @include bp('1042') {
          display:inline;
          float:left;
        }


        a {
            display:inline-block;

           height:49px;
           text-align:center;
           text-transform:uppercase;
           color:$white;;
           text-decoration:none;
           line-height:49px;
           font-size:23px;
           width:130px;

          @include bp('1042') {
            display:block;
          }

           &:hover {
             background-color: $second-color;
           }

           &.selected {
             background:url($image-path-front + 'arrow.png') center 40px no-repeat $second-color;
           }
         }
        }
      }
  }

  #small_ribbon {
    display: none;

    padding: 1em 0;
    width:100%;
    height: 100%;
    background-color:$main-color;
    position:fixed;
    z-index: 100;
    top: 0;
    text-align: center;
    background-image: url($image-path-front + 'bck_img_responsive.jpg');
    background-position: center;


    ul {
      @include bp('m1042') {
        position: absolute;
        top: 50%;
        left: 50%;

        @include vp(transform, translate(-50%, -50%));
      }
    }

    // @include bp('small') {
    //   top:3em;
    //   position: absolute;
    //   box-sizing: border-box;
    //   width: 90%
    // }

    @include bp('1042') {
      display: block!important;
      left:0;
      top:14px;
      padding:0;
      height:49px;
      position:relative;
      background-image: none;
    }
  }


.responsive-navbar {
  width: 100%;
  height: 24em;
  position: relative;
  box-sizing: border-box;

  // @include bp('small') {
  //   margin: 3em auto 0 auto;
  //   width: 90%;
  //
  // }

  @include bp('1042') {
    display: none;
  }
}

.responsive-header-img {
  width: 100%;
  height: auto;
  display: block;


  @include bp('1042') {
    display: none;
  }
}

.logo-responsive {
  position: absolute;
  left: 50%;
  top: 50%;
  width: em(272);
  /*width: 75%;*/
  /*height: auto;*/
  @include vp(transform, translate(-50%,-50%))

  @include bp('1042') {
    display: none;
  }
}

.gradient-image {
  position: absolute;
  @include same(left,top,right,bottom, 0);
  background-size: cover;
  z-index: 10;
}

.gradient-responsive {
  overflow: hidden;
  z-index: 50;
  position: absolute;
  @include same(left,top,right,bottom, 0);
  background: -moz-linear-gradient(top, rgba(148,0,211,0) 0%, rgba(148,0,211,0) 1%, rgba(148,0,211,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(148,0,211,0) 0%,rgba(148,0,211,0) 1%,rgba(148,0,211,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(148,0,211,0) 0%,rgba(148,0,211,0) 1%,rgba(148,0,211,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009400d3', endColorstr='#9400d3',GradientType=0 ); /* IE6-9 */
}
