@import 'normalize';

@import '../functions';
@import '../mixins';
@import '../vars';
@import '../helpers';
@import '../burger';

@import 'fonts';
@import 'commons';

// PAGES
@import 'pages/header';
@import 'pages/form';
@import 'pages/stage';
@import 'pages/gallery';
@import 'pages/partners';
@import 'pages/footer';
