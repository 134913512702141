* {
  margin:0;
  padding:0;
  border:0;
  }

body {
  background:url($image-path-front + 'bck_img.jpg') left top repeat $white;
  font-family:'league_gothicregular' , arial, sans-serif;
  font-weight:400;
}

#mainContener {
  width: 100%;
  margin:0 auto;

  @include bp('1042') {
  width:1018px;
  }



}

.contener {

  width: 100%;
  margin:0 auto;
  position: relative;

  @include bp('1042') {
    width:960px;
  }


}

.subContener {
  width: 100%;
  box-sizing: border-box;
  margin:0 auto;
  background:#FFF;
  padding: 2.75em 2em;

   @include bp('small') {
      padding: 2.75em 3.25em;
   }

  @include bp('1042') {
    width:712px;
    box-sizing: content-box;
    margin-top:10px;
    padding:65px 95px;
  }




}



			.title {

				color:$black;
				text-transform:uppercase;
				float:left;
				font-weight:400;
				font-family:'league_gothicregular' , arial, sans-serif;
        margin: 0;
        font-size: 60px;
        line-height:55px;

        @include bp('1042') {
          font-size:100px;
          line-height:95px;
        }
			}

			.imageTitle {
        display: none;

        @include bp('1042') {
          display: block;
  				float:right;
  				height:190px;
        }

			}

			.clear{
				clear:both;
			}

			.subTitle {
				color:$second-color;
				font-size:30px;
				text-transform:uppercase;
				letter-spacing:2px;
				font-family:'league_gothicregular' , arial, sans-serif;
				margin-top:15px;
				line-height:45px;
				font-weight:400;

        @include bp('1042') {
          font-size:34px;
          line-height:70px;
        }
			}

      p {
        font-family:Verdana, Geneva, sans-serif;
        color:$black;
        font-size:16px;
        text-align:justify;
        line-height:30px;

        @include bp('1042') {
            letter-spacing:2px;
        }
      }


      input, textarea {
        border: 1px solid #e6e6e6;
        font-size:30px;
        padding:10px;
        width:307px;
        font-family:Verdana, Geneva, sans-serif;
      }

      textarea {
        height:146px;
      }

      input{
        &[type=submit]{
          width:327px;
          font-family:'league_gothicregular' , arial, sans-serif;
          background-color:$second-color;
          color:$white;
          text-transform:uppercase;
          border:0px;
        }

        &[type=submit]:hover {
          background-color:$main-color;
          cursor:pointer;
        }
    }


      .disabled {
        background-color:#12ad06!important;
        cursor:default!important;
        color:#fff!important;

        &:hover {
          background-color:#12ad06!important;
        }
      }


      label {
        font-size:28px;
        text-transform:uppercase;
         span {
          color:$second-color;
        }
      }

      form {
        margin-top:40px;

        div {
          margin-bottom:10px;
          height:100px;
          width:347px;
        }
      }

      .error input, .error textarea{
        border: 1px solid #FFB3B3;
      }

      .left {
        float:left;
        height:304px;
      }

      .right {
        float:right;
      }


      .btn {
        @include vp(transition, (background-color .5s ease, color .5s ease))
        text-decoration: none;
        color: $white;
        text-align: center;
        padding: 1em .5em;
        border-radius: 5px;
        border-width: 3px;
        border-style: solid;
        margin-top: 2em;
        vertical-align: middle;

        &:hover {
          background-color: transparent;
          cursor: pointer;

        }



        @include m(add-big) {
          margin: 2em 0;
          background-color: $second-color;
          border-color: $second-color;
          display: inline-block;
          padding: 1em 3em;
          font-weight: 500;

          &:hover {
            color: $second-color;
          }
        }

      }


      .menu-button {
    	display: inline-block;
    	padding: .75em;
    	background-color: #fafafa;
      border-radius: 50%;
      position: fixed;
      z-index: 1000;
      top: 1em;
      left: 1em;
      @include vp(box-shadow, (0px 0px 20px 0px #656565))

      // @include bp('small') {
      //   position: absolute;
      //   top: 1em;
      //   left: 1em;
      // }

      @include bp('1042') {
        display: none;
      }
    }

    .burger-icon {
    	@include burger(15px, 2px, 4px, #444);
    }

    .menu-button.is-active .burger-icon {
    	@include burger-to-cross;
    }


    a[href^="tel"] {
      color: $black;
      text-decoration: none;
    }
