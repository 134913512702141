@mixin centered {
  @include vendor-prefixes(transform, translate(-50%, -50%))
  left: 50%;
  top: 50%;
  position: absolute;
}

@mixin p-el($el, $el-width : null, $el-height : null) {
  &:#{$el} {
    @extend %pseudos;
    width: $el-width;
    height: $el-height;
    @content;
  }
}

@mixin vendor-prefixes($property, $value) {
  #{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  -webkit-#{$property}: $value;
}

@mixin vp($property, $value) {
  @include vendor-prefixes($property, $value);
}

@mixin round-icon($width, $background, $color, $roundness: null) {
  @include vendor-prefixes(border-radius, $roundness);
  background-color: $background;
  height: $width;
  width: $width;
  text-align: center;
  color: $color;
}

@mixin same($values...) {
  $length: length($values);
  $value: nth($values, $length);
  @for $i from 1 to $length {
    #{nth($values, $i)}: $value;
  }
}

@mixin bp($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  @else {
    @warn "Aucun breakpoint : `#{$breakpoint}`. "
        + "Breakpoints disponibles : #{map-keys($breakpoints)}.";
  }
}

@mixin element($element) {
  &__#{$element} {
    @content;
  }
}

@mixin modifier($modifier) {
  &--#{$modifier} {
    @content;
  }
}

@mixin e($element) {
  @include element($element)  {
    @content;
  }
}

@mixin m($modifier) {
  @include modifier($modifier) {
    @content;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
