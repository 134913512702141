#gallerie {
  margin-top:30px;
  position: relative;

  a {


    overflow:hidden;
    margin-bottom:5%;
    position: relative;
    display: inline-block;


    &:after {
      @include bp('m1042') {
        display: block;
        padding-bottom: 60%;
        content: ' ';
      }
    }

    @include bp('1042') {
      height:150px;
      width:224px;
      float:left;
      margin-right:20px;
    }

    @include bp('m1042') {
      width: 47.5%;
      margin-right: 5%;
    }

    @include bp('msmall') {
      width: 100%;
    }

    &:nth-child(3n+3) {
      @include bp('1042') {
      margin-right:0!important;
      margin-bottom:20px;
    }
    }

    &:nth-child(2n+2) {
      @include bp('m1042') {
      margin-right:0!important;
    }
    }

    &:hover img {
      @include bp('1042')  {
      width:288px;
    }

    @include bp('m1042') {
      width: 120%;
    }

    }

    &.last {
      margin-right:0!important;
    }

    img {
      position: absolute;
      width: 130%;
      height: auto;
      top: 0;
      left: 0;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;

      @include bp('1042') {
        width: 350px;
        position: relative;
      }
      }
  }
}

.fancybox-title-float-wrap .child {
    background: $main-color;
  }
